<template>
  <v-container fluid style="padding-top:64px" class="px-0 pb-0 nav-content">
    <v-card flat height="100%" class="pb-0">
      <v-navigation-drawer mini-variant-width="80" expand-on-hover app color="#000" dark>
        <v-list>
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-icon size="40">mdi-account-circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{companyInformation.people}}</v-list-item-title>
              <v-list-item-subtitle>管理员</v-list-item-subtitle>
            </v-list-item-content>
            <v-btn icon @click.stop="mini = !mini">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item v-for="item in items" :key="item.title" link :to="item.router">
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <router-view />
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
    mini: true,
    items: [
      { title: "首页", router: "/" },
      { title: "公司信息", router: "CompanyInformation" },
      { title: "液货船管理", router: "TankerManagement" },
      { title: "散货船管理", router: "BulkCarrierManagement" },
      { title: "人员管理", router: "PeopleManagement" },
      { title: "充值记录", router: "RechargeRecord" },
      { title: "消费记录", router: "ExpensesRecord" }
    ],
    companyInformation: ""
  }),
  created() {
    this.info();
  },
  methods: {
    info() {
      this.infonList = JSON.parse(localStorage.getItem("info"));
      this.$server
        .getAdministratorMsg({ firmid: this.infonList.firmid })
        .then(data => {
          this.companyInformation = data.data;
        });
    }
  }
};
</script>

<style >
.nav-content {
  height: 100%;
}
</style>